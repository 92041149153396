<style lang="scss" scoped></style>
<template>
	<div class="animated fadeIn">
		<div class="card-collapsible px-0">
			<div class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer" @click="handleCollapsibleHeader('work-general-information')">
				<div class="title w-90">
					<b-icon icon="file-earmark-text" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
						FormMSG(1, 'Work general information')
					}}</span>
				</div>
				<div class="cursor-pointer d-flex justify-content-end w-10">
					<component v-if="!configAccordion['work-general-information'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
					<component v-if="configAccordion['work-general-information'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
				</div>
			</div>
		</div>
		<b-collapse :visible="configAccordion['work-general-information'].isVisible">
			<div class="content-card-collapsible">
				<department-selection
					ref="depfun"
					:edit-data="contract"
					:disable-edit="isEditable() === false"
					burger-field
					@change="handleDepartmentAndFunctionChange"
				/>
				<b-row v-if="!isFilmSingle && !isFilmSingleFree">
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'startDate'" :label="FormMSG(2, 'Start date')">
							<b-form-input
								v-uni-id="'startDate'"
								:disabled="isEditable() === false"
								:value="contract.startDate && contract.startDate.split('T')[0]"
								type="date"
								@input="updateStartDate"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'endDate'" :label="FormMSG(3, 'End date (optional)')">
							<b-form-input
								v-uni-id="'endDate'"
								:disabled="isEditable() === false"
								:value="contract.endDate && contract.endDate.split('T')[0]"
								type="date"
								@input="updateEndDate"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'daysforeseens'" :label="FormMSG(4, 'Number of workign days foreseen')">
							<b-form-input v-model="contract.daysForeseen" v-uni-id="'daysforeseens'" :disabled="isEditable() === false" type="number" />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'employeeType'" :label="FormMSG(5, 'Employee type')">
							<b-form-select v-model="contract.employeeStatus" :options="employeeStatusOptions" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="!isFilmSingle && !isFilmSingleFree">
					<!-- isFilmSingleUserFree -->
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'costCenterPrepsType'" :label="FormMSG(6, 'Cost center preps')">
							<v-select :options="[]" id="costCenterPrepsType" v-model="contract.costCenterPrepsType" />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'costCenterShootingType'" :label="FormMSG(7, 'Cost center shooting')">
							<v-select :options="[]" id="costCenterShootingType" v-model="contract.costCenterShootingType" />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group v-uni-for="'costCenterWrapType'" :label="FormMSG(8, 'Cost center wrap')">
							<v-select :options="[]" id="costCenterWrapType" v-model="contract.costCenterWrapType" />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="12" lg="3" xl="3">
						<b-form-group :label="FormMSG(9, 'Budget cost factor')" v-uni-for="'bcfRate'">
							<b-form-input
								v-model="contract.companyCostFactor"
								v-uni-id="'bcfRate'"
								type="number"
								step="0.00000000001"
								min="1"
								placeholder="1.0"
							></b-form-input>
							<div class="info-message">
								<div><info :size="16" /></div>
								<div class="label">{{ FormMSG(10, 'budget cost = salary*factor') }}</div>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="12">
						<fieldset class="card-inside">
							<legend class="card-inside">{{ FormMSG(11, 'Document package') }}</legend>
							<b-row class="inline-flex align-items-center">
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-form-group v-uni-for="'documentPackageId'" :label="FormMSG(12, 'Assign document package')">
										<v-select :options="[]" id="documentPackageId" v-model="contract.documentPackageId" />
									</b-form-group>
								</b-col>
								<b-col sm="12" md="12" lg="6" xl="6">
									<b-row>
										<b-col sm="12">
											<!--  class="inline-flex align-items-center" -->
											<div class="btn-rounded" @click="handleViewDocumentPackage">
												<span class="icon">
													<eye color="#5B6164D6" :size="16" />
												</span>
												<span class="label">
													{{ FormMSG(13, 'View document package') }}
												</span>
											</div>
										</b-col>
									</b-row>
									<b-row class="pt-3">
										<b-col sm="12">
											<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="contract.isAutomaticSentContract">{{
												FormMSG(14, 'Send automatically once invitation is accepted')
											}}</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row class="pt-3">
										<b-col sm="12">
											<div class="btn-rounded" @click="handleResendDocumentPackage">
												<span class="icon">
													<corner-up-left color="#5B6164D6" :size="16" />
												</span>
												<span class="label">
													{{ FormMSG(15, 'Resend document package') }}
												</span>
											</div>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
			</div>
		</b-collapse>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { ChevronDown, ChevronUp, Info, Eye, CornerUpLeft } from 'lucide-vue';
import * as icons from 'lucide-vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { isNil } from '@/shared/utils';
import { createUniqIdsMixin } from 'vue-uniq-ids';
import gql from 'graphql-tag';

const uniqIdsMixin = createUniqIdsMixin();

const query_getContractTemplate = gql`
	query ($departmentNumber: Int!, $functionNumber: Int!) {
		GetContractTemplate(DepartmentNumber: $departmentNumber, FunctionNumber: $functionNumber) {
			defaultDayType
			dailyRate
			travelDailyRate
			hourlyRate
			overtimeRate
			nightTimeRate
			hourBeforeTimeRate
			kmRate
			lunchPerDiem
			hotelPerDiem
			dailyRightTransfer
			nightTimeStart
			nightTimeEnd
			minTimeBetweenDays
			lunchMinimum
			totMinPerDay
			totMinPerWeek
			sixthDay
			seventhDay
			timeManagementType
			weeklyOvertime
			workSixDays
			weeklyFiveDaysMinimumSalary
			weeklySixDaysMinimumSalary
			weeklyFiveDaysEffNumHours
			weeklyFiveDaysEquNumHours
			weeklySixDaysEffNumHours
			weeklySixDaysEquNumHours
			weeklyBonusAmount
			weeklyFiveDaysRefSalary
			weeklySixDaysRefSalary
			weeklyOvtOneLimit
			weeklyOvtOneRate
			weeklyOvtTwoLimit
			weeklyOvtTwoRate
			weeklyOvtThreeLimit
			weeklyOvtThreeRate
			weeklyBaseEffNumHours
			hourlyRateAnnexThree
			weeklyBonusAmountFiveDays
			weeklyBonusAmountSixDays
			weeklyBaseRefSalary
			weeklyBaseMinimumSalary
			weeklyFiveDaysMinimumSalaryAnThree
			weeklySixDaysMinimumSalaryAnThree
			transportRate
			transportPaidAfter
			ovtLimit1
			ovtLimit2
			ovtRate1
			ovtRate2
			sundayPublicHolidayFactor
			companyCostFactor
			minTimeBetweenWeek
			transportPaidAfterStr
			ovtLimit1Str
			ovtLimit2Str
			minTimeBetweenWeekStr
			totMinPerDayStr
			totMinPerWeekStr
			totMinPerDayForContDay
			lunchMinimumForContDay
			totMinPerDayForContDayStr
			weeklyBaseEffNumHoursStr
			weeklyOvtOneLimitStr
			weeklyOvtTwoLimitStr
			weeklyOvtThreeLimitStr
			lunchMinimumStr
			lunchMinimumForContDayStr
			baseSalary1PayCode
			baseSalary2PayCode
			baseSalary3PayCode
			baseSalary4PayCode
			dayOvt1PayCode
			dayOvt2PayCode
			dayOvt3PayCode
			weekOvt1PayCode
			weekOvt2PayCode
			weekOvt3PayCode
			nightTimePayCode
			restPayCode
			transportPayCode
			sundayPayCode
			seventhDayPayCode
			sixthDayPayCode
			beforeTimePayCode
			travelAllowancePayCode
			carAllowancePayCode
			phoneAllowancePayCode
			computerAllowancePayCode
			boxKitAllowancePayCode
			productionFeeAllowancePayCode
			lunchPerDiemPayCode
			dinerPerDiemPayCode
			hotelPerDiemPayCode
			abroadPerDiemPayCode
			collectiveAgreement
			echelon
			analyticCode
		}
	}
`;

export default {
	name: 'WorkGeneralInformation',
	mixins: [languageMessages, isSingleProjectMixin, uniqIdsMixin],
	props: {
		disableEdit: {
			type: Boolean,
			required: true,
			default: false
		},
		entryData: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	components: {
		departmentSelection: () => ({
			component: import('@/components/DepartmentFunctionSelection')
		}),
		ChevronDown,
		ChevronUp,
		Info,
		Eye,
		CornerUpLeft
	},
	data() {
		return {
			error: '',
			configAccordion: {
				'work-general-information': {
					isVisible: true
				}
			},
			contract: {}
		};
	},
	computed: {
		employeeStatusOptions() {
			return this.FormMenu(1331);
		}
	},
	methods: {
		handleCollapsibleHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleViewDocumentPackage() {
			console.log('View Document Package');
		},
		handleResendDocumentPackage() {
			console.log('Resend Document Package');
		},
		isEditable() {
			return !this.disableEdit;
		},
		updateStartDate: function ($event) {
			if (this.contract.startDate.startsWith('T')) {
				this.contract.startDate = $event + this.contract.startDate;
			} else {
				this.contract.startDate = $event + this.contract.startDate.substring(10);
			}
		},
		updateEndDate: function ($event) {
			if (this.contract.endDate.startsWith('T')) {
				this.contract.endDate = $event + this.contract.endDate;
			} else {
				this.contract.endDate = $event + this.contract.endDate.substring(10);
			}
		},
		handleDepartmentAndFunctionChange(element) {
			// checking if need to upload new values
			if (element.department == this.contract.department && element.function == this.contract.function) {
				// no need to load contract template
				return;
			}

			this.contract = {
				...this.contract,
				...element
			};

			// loading template for new department/function
			if (
				isNaN(this.contract.department) == false &&
				isNaN(this.contract.function) == false &&
				this.contract.department != 0 &&
				this.contract.function != 0
			) {
				// load template
				const departmentNumber = parseInt(this.contract.department, 10);
				const functionNumber = parseInt(this.contract.function, 10);
				//console.log("FunctionNumber:", FunctionNumber);
				this.$apollo
					.query({
						query: query_getContractTemplate,
						variables: {
							departmentNumber,
							functionNumber
						},
						fetchPolicy: 'no-cache'
					})
					.then((result) => {
						console.log(this.$refs.memo);
						this.$refs.memo.contract = {
							...this.$refs.memo.contract,
							...result.data.GetContractTemplate
						};
						this.$refs.memo.computeSMG();
					})
					.catch((error) => {
						this.error = error;
						console.error({ error });
						// this.errors.push(error);
					});
			}
		}
	},
	watch: {
		entryData: {
			handler(data) {
				if (!isNil(data)) {
					this.contract = data;
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
